<template>
  <button
    class="btn btn-secondary btn-sm"
    :title="$t(`deliveryDetails.createDelivery`)"
    v-on:click="generate"
    :disabled="!value.need_delivery"
    v-if="value.last_public_tracking_code === null"
  >
    <BaseIcon name="box-add" />
  </button>
  <div v-if="value.last_public_tracking_code">
    <button
      class="btn btn-transparent btn-sm badge badge-outline me-1"
      v-on:click="show(value.last_public_tracking_code, value.method_id)"
    >
      {{ value.last_public_tracking_code }}
    </button>
    <button
      class="btn btn-transparent btn-sm badge badge-outline me-1"
      v-if="value.public_tracking_codes?.length > 1"
      v-on:click="showTrackingCodes"
    >
      + {{ value.public_tracking_codes?.length - 1 }}
    </button>
    <button
      class="btn btn-secondary btn-sm btn-no-r"
      :title="$t(`deliveryDetails.printLabel`)"
      v-on:click="printLabel(value.last_public_tracking_code, value.method_id)"
    >
      <BaseIcon name="printer" />
    </button>
    <button
      class="btn btn-secondary btn-sm btn-no-l"
      :class="{ 'btn-no-r': value.need_delivery }"
      :title="$t(`assetFinder.viewDeliveryStatus`)"
      v-on:click="show(value.last_public_tracking_code, value.method_id)"
    >
      <BaseIcon
        :name="
          deliveredStatuses[value.delivered_status]
            ? deliveredStatuses[value.delivered_status].icon
            : 'box-search'
        "
      />
    </button>
    <button
      class="btn btn-secondary btn-sm btn-no-l"
      :title="$t(`deliveryDetails.createDelivery`)"
      v-on:click="generate"
      v-if="value.need_delivery"
    >
      <BaseIcon name="box-add" />
    </button>
  </div>
  <DeliveryStatusModal
    v-model="value"
    ref="modal"
    v-model:tracking-code="trackingCode"
    v-model:method="methodId"
  />
  <Modal ref="codesModal" :title="$t(`countries.deliveries`)">
    <div
      class="row text-start my-1"
      v-for="code in value.public_tracking_codes"
      :key="code"
    >
      <div class="col-8">
        <button
          class="btn btn-transparent"
          :class="{ 'text-gray': code.status === 7 }"
          v-on:click="show(code.public_tracking_code, code.delivery_method_id)"
        >
          {{ code.public_tracking_code }}
          <BaseIcon name="trash" class="regular-12" v-if="code.status === 7" />
        </button>
      </div>
      <div class="col-4 text-end">
        <button
          class="btn btn-secondary btn-sm btn-no-r"
          :title="$t(`deliveryDetails.printLabel`)"
          v-on:click="
            printLabel(code.public_tracking_code, code.delivery_method_id)
          "
        >
          <BaseIcon name="printer" />
        </button>
        <button
          class="btn btn-secondary btn-sm btn-no-l"
          :title="$t(`assetFinder.viewDeliveryStatus`)"
          v-on:click="show(code.public_tracking_code, code.delivery_method_id)"
        >
          <BaseIcon name="box-search" />
        </button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { defineAsyncComponent } from "vue";
import deliveredStatuses from "@/modules/DeliveredStatuses";
import DeliveryStatusModal from "@/components/modals/DeliveryStatusModal";
import Modal from "@/components/modals/Modal";
import store from "@/store";
import printJS from "print-js";
import tenant from "../../../modules/tenant";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "DeliveryDetails",
  components: { Modal, DeliveryStatusModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
    method: Number,
  },
  data() {
    return {
      deliveredStatuses: deliveredStatuses,
      value: this.modelValue,
      trackingCode: "",
      methodId: 0,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    show(code, method = null) {
      this.trackingCode = code;
      this.methodId = method;
      setTimeout(() => {
        this.$refs.modal.showModal();
        this.$refs.codesModal.hideModal();
      }, 300);
    },
    showTrackingCodes() {
      this.$refs.codesModal.showModal();
    },
    generate() {
      window.apps.callEvent("generate:delivery", this.id);
      this.$emit("generate");
    },
    printLabel(code, method) {
      /*  window.open(
          `${tenant.getApiBaseUrl()}/deliveries/label/${method}?code=${code}&_token=${store.state.token}`,
          "_blank"
      );*/
      printJS(
        `${tenant.getApiBaseUrl()}/deliveries/label/${method}?code=${code}&_token=${
          store.state.token
        }`
      );
    },
  },
  emits: ["update:modelValue", "change", "generate"],
};
</script>
